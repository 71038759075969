import React, { useContext } from 'react'
import { LoadingContext } from '@layouts'
import { Footer, Head, Link, Translate } from '@components'
import locale from '@data/locales/404'
import * as st from '@assets/styl/Page404.module.styl'

export default ({ location: { pathname } }) => {
  const { toggleVisible: toggleLoading } = useContext(LoadingContext)
  toggleLoading(false)

  return <>
    {Translate(locale, 'head_title', title => <Head title={title} path={pathname} />)}

    <section className={st.core}>
      <h1>{Translate(locale, 'title')}</h1>
      <h2>{Translate(locale, 'subtitle')}</h2>
      <p>{Translate(locale, 'description')}</p>
      <Link to="">{Translate(locale, 'link')}</Link>
    </section>

    <Footer />
  </>
}