export default {
  head_title_pt: 'Rotesma - Página não encontrada',
  head_title_en: 'Rotesma - Page not found',
  head_title_es: 'Rotesma - Página no encontrada',
  title_pt: 'Ops... Página não encontrada.',
  title_en: 'Ops... Page not found.',
  title_es: 'Ops... Página no encontrada.',
  subtitle_pt: 'Erro 404.',
  subtitle_en: 'Erro 404.',
  subtitle_es: 'Erro 404.',
  description_pt: 'A Página que você tentou acessar não existe ou está fora do ar, verifique o endereço da URL e tente novamente.',
  description_en: 'A Página que você tentou acessar não existe ou está fora do ar, verifique o endereço da URL e tente novamente.',
  description_es: 'La página a la que intentó acceder no existe o está caída, verifique la dirección URL e intente nuevamente .',
  link_pt: 'Ir para a home',
  link_en: 'Ir para a home',
  link_es: 'Ir a la página de inicio'
}
